<template>
	<header>
		<div class="center">
			<div class="menu-icon">
				<a @click.prevent="toggleMenu">
					<span><i class="fas fa-bars"></i></span>
				</a>
			</div>
			<figure>
				<router-link :to="{ name: 'MainIndex' }">
					<img src="/imagenes/sportsxpress-logo-75x85.png" alt="Main Logo" />
				</router-link>
			</figure>
			<menu>
				<ul>
					<router-link :to="{ name: 'Sizechart' }">SIZE CHART</router-link>
					<router-link :to="{ name: 'OrderForm' }">ORDER FORM</router-link>
					<router-link :to="{ name: 'PrivacyPolicy' }"
						>PRIVACY POLICY</router-link
					>
					<router-link :to="{ name: 'ContactUs' }">CONTACT US</router-link>
				</ul>

				<router-link :to="{ name: 'ContactUs' }" class="contactLink">
					<img src="/imagenes/mail-icon.png" alt="Contact Form" />
				</router-link>
				<a
					href="https://www.facebook.com/Sports-Xpress-Apparel-262671614582562"
					target="_blank"
				>
					<img src="/imagenes/facebook-icon.png" alt="Facebook Icon" />
				</a>
				<a href="https://www.instagram.com/sx_sportswear/" target="_blank">
					<img src="/imagenes/instagram-icon.png" alt="Intagram Icon" />
				</a>
			</menu>
			<div class="searchInput">
				<input type="search" @keypress.enter="search" />
				<span>
					<i class="fas fa-search"></i>
				</span>
			</div>
		</div>
		<nav>
			<div class="center">
				<li class="navMenuLogo">
					<div class="close">
						<a @click.prevent="toggleMenu">
							<i class="fas fa-times-circle"></i>
						</a>
					</div>
					<figure>
						<img
							src="/imagenes/sportsxpress-logo-150x48.png"
							alt="Navegation menu logo"
						/>
					</figure>
				</li>
				<li v-for="item in menuItems" :key="item.id">
					<router-link v-if="item.link" :to="{ name: item.link }">{{
						item.title
					}}</router-link>
					<a v-else @click.prevent="showSub">{{ item.title }}</a>
					<div v-if="item.submenu" class="submenu">
						<router-link
							v-for="subItem in item.submenu"
							:key="subItem.title"
							:to="subItem.url"
						>
							{{ subItem.title }}
						</router-link>
					</div>
				</li>
			</div>
		</nav>
	</header>
</template>

<script>
import useMenu from "../routers/sitemap";
import { ref, onMounted } from "vue";

export default {
	name: "MainHeader",
	components: {},
	setup() {
		const menu = useMenu();
		const menuItems = ref(null);

		onMounted(async () => {
			await menu.load();
			menuItems.value = menu.data.value.sections;
		});

		return { menuItems };
	},
	methods: {
		showSub(evt) {
			evt.target.parentElement.classList.toggle("show-sub");
		},
		toggleMenu() {
			document.querySelector("header nav").classList.toggle("show-navMenu");
		},
		search(evt) {
			if (evt.target.value.trim()) {
				this.$router.push({
					name: "Search",
					params: { query: evt.target.value },
				});
			}
		},
	},
};
</script>
