<template>
	<Header />
	<router-view />
	<Footer />
</template>
<script>
import Header from "./components/MainHeader.vue";
import Footer from "./components/MainFooter.vue";

export default {
	name: "App",
	components: { Header, Footer },
};
</script>

<style></style>
