import { createRouter, createWebHistory } from "vue-router";

const routes = [
	{
		path: "/",
		name: "MainIndex",
		component: () =>
			import(/* webpackChunkName: "index-view" */ "../views/HomePage.vue"),
	},
	{
		path: "/:category/:id/:subcategory?",
		name: "ProductList",
		component: () =>
			import(
				/* webpackChunkName: "productList-view" */ "../views/ProductsList.vue"
			),
		meta: {
			title: "super hola mundo",
		},
	},
	{
		path: "/search/:query?",
		name: "Search",
		component: () =>
			import(
				/* webpackChunkName: "productList-view" */ "../views/ProductsList.vue"
			),
		meta: {
			title: "super hola mundo",
		},
	},
	{
		path: "/sizechart",
		name: "Sizechart",
		component: () =>
			import(/* webpackChunkName: "sizechart-view" */ "../views/SizeChart.vue"),
		meta: {
			title: "super hola mundo",
		},
	},
	{
		path: "/accesories",
		name: "AccesoriesList",
		component: () =>
			import(
				/* webpackChunkName: "accesorieslist-view" */ "../views/AccesoriesList.vue"
			),
	},
	{
		path: "/product/:model",
		name: "Product",
		props: true,
		component: () =>
			import(/* webpackChunkName: "product-view" */ "../views/Product.vue"),
		meta: {
			title: "super hola mundo",
		},
	},
	{
		path: "/get-started",
		name: "GetStarted",
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "getstarted-view" */ "../views/GetStarted.vue"
			),
		meta: {
			title: "super hola mundo",
		},
	},
	{
		path: "/contact-us",
		name: "ContactUs",
		props: true,
		component: () =>
			import(/* webpackChunkName: "contactus-view" */ "../views/ContactUs.vue"),
		meta: {
			title: "super hola mundo",
		},
	},
	{
		path: "/order-form",
		name: "OrderForm",
		props: true,
		component: () =>
			import(/* webpackChunkName: "OrderForm-view" */ "../views/OrderForm.vue"),
		meta: {
			title: "super hola mundo",
		},
	},
	{
		path: "/privacy-policy",
		name: "PrivacyPolicy",
		props: true,
		component: () =>
			import(
				/* webpackChunkName: "privacypolicy-view" */ "../views/PrivacyPolicy.vue"
			),
		meta: {
			title: "super hola mundo",
		},
	},
];

const router = new createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	/**
	 * Close movil menu
	 */
	document.querySelector("header nav").classList.remove("show-navMenu");

	/**
	 * Mark in white color the menu element
	 */
	const liTags = document.querySelectorAll("header nav li > a");
	const category = to.params.category;
	liTags.forEach((ele) => {
		const aText = ele.textContent.toLocaleLowerCase();
		if (category === aText || to.path === "/" + aText) {
			ele.parentElement.classList.add("active");
		} else {
			ele.parentElement.classList.remove("active");
		}
	});

	next();
});

export default router;
