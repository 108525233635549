<template>
	<footer>
		<div class="gray">
			<div class="center">
				<article>
					<h4>ABOUT US</h4>
					<p>
						SX sportwear specializes in Baseball, Softball, Basketball,
						Voleyball and Soccer sublimated uniforms with fully customizable
						designs. Utilizing the best technology in sublimation, lightweight
						fabrics and the best prices in the industry.
					</p>
				</article>
				<div>
					<h4>INFO</h4>
					<ul>
						<li>
							<router-link :to="{ name: 'Sizechart' }">CHART</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'OrderForm' }">ORDER FORM</router-link>
						</li>
						<li>
							<router-link :to="{ name: 'PrivacyPolicy' }"
								>PRIVACY POLICY</router-link
							>
						</li>
						<li>
							<router-link :to="{ name: 'ContactUs' }">CONTACT US</router-link>
						</li>
					</ul>
				</div>
				<div>
					<h4>PACKAGE DEALS</h4>
					<ul>
						<li>
							<router-link
								:to="{
									name: 'ProductList',
									params: {
										id: 4,
										subcategory: 'package-deals',
										category: 'baseball',
									},
								}"
								>BASEBALL</router-link
							>
						</li>
						<li>
							<router-link
								:to="{
									name: 'ProductList',
									params: {
										id: 10,
										subcategory: 'package-deals',
										category: 'basketball',
									},
								}"
								>BASKETBALL</router-link
							>
						</li>
						<li>
							<router-link
								:to="{
									name: 'ProductList',
									params: {
										id: 16,
										subcategory: 'package-deals',
										category: 'volleyball',
									},
								}"
								>VOLLEYBALL</router-link
							>
						</li>
						<li>
							<router-link
								:to="{
									name: 'ProductList',
									params: {
										id: 22,
										subcategory: 'package-deals',
										category: 'soccer',
									},
								}"
								>SOCCER</router-link
							>
						</li>
						<li>
							<router-link
								:to="{
									name: 'ProductList',
									params: {
										id: 15,
										subcategory: 'package-deals',
										category: 'softball',
									},
								}"
								>SOFTBALL</router-link
							>
						</li>
					</ul>
				</div>
				<div class="redes">
					<h4>CONTACT US</h4>
					<p>
						Email:
						<a href="mailto:sales@sxsportswear.com">sales@sxsportswear.com</a>
					</p>
					<p>Phone: <a href="tel:7603328266">(760) 332 8266</a></p>
					<p class="icons">
						<router-link :to="{ name: 'ContactUs' }" class="contactLink">
							<img src="/imagenes/mail-icon.png" alt="Contact Form" />
						</router-link>
						<a
							href="https://www.facebook.com/Sports-Xpress-Apparel-262671614582562"
							target="_blank"
						>
							<img src="/imagenes/facebook-icon.png" alt="Facebook Icon" />
						</a>
						<a href="https://www.instagram.com/sx_sportswear/" target="_blank">
							<img src="/imagenes/instagram-icon.png" alt="Intagram Icon" />
						</a>
					</p>
				</div>
			</div>
		</div>
		<div class="blue">
			<div class="center">
				<figure>
					<img
						src="/imagenes/sportsxpress-isotipo-46x43.png"
						alt="Footer Isotype"
					/>
				</figure>
				<p>&copy; sxsportswear.com - {{ new Date().getFullYear() }}</p>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: "MainFooter",
};
</script>
