import {ref} from "vue";

export default function (){
  const data = ref(null);
  const error = ref(null);

  const load = async() => {
    try {
      const resp = await fetch("/_scripts/files/main.json");
      data.value = await resp.json();
    } catch (error) {
      console.error(error);
      error.value = error;
    }
  }
  return { load, data, error };
};